import React from 'react'
import Service from './user.service'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Filters from './filters.component'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import { FormatDate } from '../../../utils/UIHelper'
import iconDelete from '../../../assets/icons/delete-red.svg'
import iconEdit from '../../../assets/icons/edit-dark.svg'
import DeleteUser from './components/delete/delete.component'

class Users extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadUsers()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadUsers())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadUsers()
    }

    onDelete = id => this.setState({
        ...this.state,
        users: this.state.users.filter(user => user.id !== id)
    })

    getStatusLabel = status => {
        switch (status) {
            case 1:
                return "Active"
            case 2:
                return "Suspended"
            case 3:
                return "Reset Required"
            default:
                return "Unknown"
        }
    }

    toggleShowDelete = user => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        userToDelete: user
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        users: this.state.users.filter(user => user.id !== this.state.userToDelete.id)
    })

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteUser user={this.state.userToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="users">
                    <div className="header">
                        <h4>Users</h4>
                        <div className="buttons">
                            <Link className="btn" to="/security/users/create"><img alt="" src={iconNew} />New User</Link>
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Roles</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.users.map((user, key) => <tr key={key}>
                                    <td>{user.username}</td>
                                    <td>{user.firstname} {user.lastname}</td>
                                    <td>{user.userRoles.length}</td>
                                    <td>{this.getStatusLabel(user.status)}</td>
                                    <td>{FormatDate(user.created * 1000)}</td>
                                    <td>{FormatDate(user.updated * 1000)}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/security/users/' + user.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(user)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Users))