import axios from 'axios'
import {API_ENDPOINT} from '../../../config'

const getDefaultState = () => {
    return {
        loadingSales: false,
        orders: [],
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadSales = instance => {
    instance.setState({ ...instance.state, loadingSales: true })
    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }
    axios({
        method: 'post',
        url: API_ENDPOINT + "/orders",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: instance.state.pageSize,
            criteria: criteria
        }
    }).then(response => handleLoadSalesResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingSales: false
            })
            alert(error)
        })
}

const handleLoadSalesResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingSales: false,
                orders: response.data.data.orders,
                total: response.data.data.size
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingSales: false
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        loadSales: () => loadSales(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service