import Validation from '../../../data/validation/validation'
import { StringValidationRule, PhoneValidationRule, EmailValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.customer.type, (error) => instance.state.errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    if(String(instance.state.customer.type) === "2"){
        validation.addValidationRule(StringValidationRule, instance.state.customer.userId, (error) => instance.state.errors.userId = error, { min: { value: 5, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
        validation.addValidationRule(StringValidationRule, instance.state.customer.ipAddress, (error) => instance.state.errors.ipAddress = error, { min: { value: 5, error: "Invalid" }, max: { value: 15, error: "Invalid" } })
    }
    validation.addValidationRule(StringValidationRule, instance.state.customer.name, (error) => instance.state.errors.name = error, { min: { value: 3, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.customer.phone, (error) => instance.state.errors.phone = error, { allowNull: false })
    validation.addValidationRule(EmailValidationRule, instance.state.customer.email, (error) => instance.state.errors.email = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.customer.address, (error) => instance.state.errors.address = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.customer.siteId, (error) => instance.state.errors.siteId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    if(typeof instance.state.customer.password !== 'undefined'){
        validation.addValidationRule(StringValidationRule, instance.state.customer.password, (error) => instance.state.errors.password = error, { min: { value: 4, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
    }

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}