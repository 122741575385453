import React from 'react'
import './info.styles.scss'
import { FormatDate } from '../../../../../../utils/UIHelper'
import iconDelete from '../../../../../../assets/icons/delete-red.svg'
import iconEdit from '../../../../../../assets/icons/edit-dark.svg'
import Service from './info.service'
import Dialog from '../../../../../../components/dialog/dialog.component'
import DeleteCustomerPlan from '../delete/delete.component'
import EditCustomerPlan from '../edit-plan/edit-plan.component'
import { connect } from 'react-redux'
import AuthHelper from '../../../../../../utils/AuthHelper'
import { GetStatusLabel } from '../../../data/labels'

class Info extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onShowEditClick = customerPlan => {
        this.setState({
            ...this.state,
            customerPlan: customerPlan,
            showEdit: true
        })
    }

    toggleShowEdit = () => this.setState({
        ...this.state,
        showEdit: !this.state.showEdit
    })

    toggleShowDelete = customerPlan => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        customerPlanToDelete: customerPlan
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        activeCustomerPlans: this.state.activeCustomerPlans.filter(customerPlan => customerPlan.id !== this.state.customerPlanToDelete.id)
    })

    onCustomerPlanUpdated = customerPlan => {
        let activeCustomerPlans = this.state.activeCustomerPlans
        for (let i = 0; i < activeCustomerPlans.length; i++) {
            if (activeCustomerPlans[i].id === customerPlan.id) {
                activeCustomerPlans[i] = customerPlan
            }
        }
        this.setState({
            ...this.state,
            activeCustomerPlans: activeCustomerPlans
        })
    }

    render() {
        let authHelper = AuthHelper(this.props.auth)
        console.log(this.props.status)
        return (
            <div className="info">
                {this.state.showEdit && <Dialog><EditCustomerPlan {...this.state.customerPlan} site={this.props.site} onClose={this.toggleShowEdit} onCustomerPlanUpdated={this.onCustomerPlanUpdated} /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteCustomerPlan customerPlan={this.state.customerPlanToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="col">
                    <table>
                        <tbody>
                            <tr>
                                <td>UserID</td>
                                <td className={GetStatusLabel(this.props)}>{this.props.userId}</td>
                            </tr>
                            {
                                this.props.type === 0 &&
                                <tr>
                                    <td>IP Address</td>
                                    <td>{this.props.ipAddress}</td>
                                </tr>
                            }
                            <tr>
                                <td>Current Plan</td>
                                <td>{this.props.plan !== null ? (this.props.plan.name + " - N" + this.props.plan.amount) : "None"}</td>
                            </tr>
                            <tr>
                                <td>Expiration</td>
                                <td>{this.props.expiration !== null ? new Date(this.props.expiration * 1000).toLocaleString() : ""}</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>{this.props.name}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{this.props.phone}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{this.props.email}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{this.props.address}</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td>{this.props.site.name}, {this.props.site.area}, {this.props.site.location.name}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="header">
                        <div className="buttons">
                            {authHelper.canAddCustomerPlan() && <button className="btn" onClick={this.props.onAddPlanClick}>Add Plan</button>}
                            {authHelper.canChangeCustomerPlan() && <button className="btn" onClick={this.props.onChangePlanClick}>Change Plan</button>}
                            {authHelper.canChangeCustomerPlan() && this.props.expired && <button className="btn" onClick={this.props.onMakeActiveClick}>Make Active</button>}
                            {authHelper.canEditCustomer() && <button className="btn" onClick={this.props.onEditClick}>Edit</button>}
                            {authHelper.canChangeCustomerPlan() && this.props.status === 0 && <button className="btn" onClick={this.props.onLockClick}>Lock</button>}
                            {authHelper.canChangeCustomerPlan() && this.props.status === 1 && <button className="btn" onClick={this.props.onUnlockClick}>Unlock</button>}
                            {authHelper.canChangeCustomerPlan() && <button className="btn" onClick={this.props.onDisconnectClick}>Clear Cache</button>}
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="header">
                        <h4>Active Plans</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Created</th>
                                {(authHelper.canModifyCustomerPlan() || authHelper.canDeleteCustomerPlan()) && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.activeCustomerPlans.map((customerPlan, key) => <tr key={key}>
                                    <td>{customerPlan.plan.name}</td>
                                    <td>{new Date(customerPlan.effective * 1000).toLocaleString()}</td>
                                    <td>{new Date(customerPlan.expiration * 1000).toLocaleString()}</td>
                                    <td>{FormatDate(customerPlan.created * 1000)}</td>
                                    {
                                        (authHelper.canModifyCustomerPlan() || authHelper.canDeleteCustomerPlan()) && <>
                                            <td>
                                                <div className="actions">
                                                    {authHelper.canModifyCustomerPlan() && <img alt="" src={iconEdit} onClick={() => this.onShowEditClick(customerPlan)} />}
                                                    {authHelper.canDeleteCustomerPlan() && <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(customerPlan)} />}
                                                </div>
                                            </td>
                                        </>
                                    }
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Info)