import React from 'react'
import { Switch, Route } from "react-router-dom"
import Sales from './sales.page'

const SaleController = () => {
    return (
        <div className="sale">
            <Switch>
                <Route path="/prepaid/sale/all" component={Sales} />
            </Switch>
        </div>
    )
}

export default SaleController