import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = () => {
    return {
        unlocking: false,
        flag: {
            type: "",
            text: ""
        }
    }
}

const unlock = (instance) => {
    instance.setState({
        ...instance.state,
        unlocking: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer/unlock",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            userId: instance.props.customer.userId
        }
    }).then(response => handleUnlockResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                unlocking: false
            })
            alert(error)
        })
}

const handleUnlockResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.props.onCustomerUpdated()
            break
        default:
            alert(response.data.message)
    }
    instance.props.onClose()
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        unlock: () => unlock(instance)
    }
}

export default Service