import React from 'react'
import { Switch, Route } from "react-router-dom"
import CustomerController from './customer/customer.controller'
import SaleController from './sales/sale.controller'
import './common.styles.scss'

const PrepaidController = () => {
    return (
        <div className="prepaid">
            <Switch>
                <Route path="/prepaid/customer" component={CustomerController} />
                <Route path="/prepaid/sale" component={SaleController} />
            </Switch>
        </div>
    )
}

export default PrepaidController