import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = () => {
    return {
        deleting: false,
        flag: {
            type: "",
            text: ""
        }
    }
}

const deletePlan = (instance) => {
    instance.setState({
        ...instance.state,
        deleting: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer/plan/delete",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.customerPlan.id
        }
    }).then(response => handleDeleteResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                deleting: false
            })
            alert(error)
        })
}

const handleDeleteResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.props.onDelete()
            break
        default:
            instance.setState({
                ...instance.state,
                deleting: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        deletePlan: () => deletePlan(instance)
    }
}

export default Service