import Validation from '../../../../../../data/validation/validation'
import { NumberValidationRule } from '../../../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../../../utils/UIHelper'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        days: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.days, (error) => instance.state.errors.days = error, { min: { value: -1000, error: "Invalid" }, max: { value: 1000, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer/plan/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.id,
            days: instance.state.days
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            instance.props.onCustomerPlanUpdated(response.data.data.customerPlan)
            instance.props.onClose()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service