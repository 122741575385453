import axios from 'axios'
import Validation from '../../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        code: "",
        name: "",
        customerSequenceStart: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.code, (error) => instance.state.errors.code = error, { min: { value: 2, error: "Invalid" }, max: { value: 6, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => instance.state.errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.customerSequenceStart, (error) => instance.state.errors.customerSequenceStart = error, { min: { value: 10000, error: "Invalid" }, max: { value: 90000, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/location/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            code: instance.state.code,
            name: instance.state.name,
            customerSequenceStart: instance.state.customerSequenceStart
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service