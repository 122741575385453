import LocationPlanDatasource from '../../../../../../data/datasource/location-plan.datasource'
import { LoadSalePoints } from '../../../../../../data/datasource/salepoint.datasource'
import Validation from '../../../../../../data/validation/validation'
import { StringValidationRule, NumberValidationRule } from '../../../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../../../utils/UIHelper'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'
import { uuid } from 'uuidv4'

const getDefaultState = instance => {
    return {
        loadingLocationPlans: false,
        loadingSalePoints: false,
        saving: false,
        planId: instance.props.customer.plan !== null? instance.props.customer.plan.id: "",
        salePointId: "",
        method: "",
        reference: "",
        quantity: "",
        locationPlans: [],
        salePoints: [],
        transactionId: uuid(),
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.planId, (error) => instance.state.errors.planId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.quantity, (error) => instance.state.errors.quantity = error, { min: { value: 1, error: "Invalid" }, max: { value: 12, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.method, (error) => instance.state.errors.method = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.salePointId, (error) => instance.state.errors.salePointId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.reference, (error) => instance.state.errors.reference = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const loadLocationPlans = instance => {

    LocationPlanDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {
            planType: instance.props.customer.type,
            location: instance.props.customer.site.location.id
        }
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/order/create/prepaid",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            customerId: instance.props.customer.id,
            salePointId: instance.state.salePointId,
            transactionId: instance.state.transactionId,
            items: [
                {
                    planId: instance.state.planId,
                    quantity: instance.state.quantity
                }
            ],
            payment: {
                method: instance.state.method,
                reference: instance.state.reference
            },
            remarks: instance.state.reference
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.props.onCustomerPlanAdded()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadLocationPlans: () => loadLocationPlans(instance),
        loadSalePoints: () => LoadSalePoints(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service