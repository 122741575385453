import React from 'react'
import Filter from '../../../components/filter/simple/simple-filter.component'

const Filters = props => {
    return (
        <Filter columns={columns} onChange={props.onChange} onSubmit={props.onSubmit} {...props.filter} />
    )
}

const columns = [
    {
        name: "userId",
        label: "UserID",
        type: "S"
    },
    {
        name: "name",
        label: "Name",
        type: "S"
    },
    {
        name: "phone",
        label: "Phone",
        type: "S"
    },
    {
        name: "expiration",
        label: "Expiration",
        type: "D"
    },
    {
        name: "status",
        label: "Status",
        type: "C",
        options: [
            {
                label: "Active",
                value: 0
            },
            {
                label: "Locked",
                value: 1
            }
        ]
    }
]

export default Filters