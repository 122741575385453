import CustomerDatasource from '../../../data/datasource/customer.datasource'
import { LoadLocations } from '../../../data/datasource/location.datasource'
import queryString from 'query-string'

const getDefaultState = instance => {
    return {
        loadingCustomers: false,
        loadingLocations: true,
        customers: [],
        locations: [],
        currentLocationId: getLocationId(instance),
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 20,
        total: 0
    }
}

const loadCustomers = instance => {
    if (!hasLocation(instance)) {
        return
    }
    let criteria = {}
    criteria.locationId = getLocationId(instance)
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            ...criteria,
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    CustomerDatasource().all(instance, {
        offset: (instance.state.currentPage - 1) * instance.state.pageSize,
        limit: instance.state.pageSize,
        criteria: criteria
    })
}

const getLocationId = instance => {
    let query = queryString.parse(instance.props.location.search);
    return query.location
}

const hasLocation = instance => {
    return typeof getLocationId(instance) !== 'undefined'
}

const Service = instance => {
    return {
        loadCustomers: () => loadCustomers(instance),
        loadLocations: () => LoadLocations(instance),
        getDefaultState: () => getDefaultState(instance),
        getLocationId: () => getLocationId(instance)
    }
}

export default Service