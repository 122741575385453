import React from 'react'
import Service from './edit.service'
import { withRouter } from 'react-router-dom'
import RoleForm from '../components/form/form.component'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'
import FormHelper from '../../../../utils/FormHelper'
import { connect } from 'react-redux'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'

class EditRole extends React.Component {
    
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount = () => {
        this.service.loadPermissions()
        this.service.loadLocations()
        this.service.loadSalePoints()
        this.service.loadRole()
    }

    onChangePermission = (permissionId, checked) => this.service.onChangeRolePermission(permissionId)

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    onCancel = () => this.props.history.push('/security/roles/all')

    render(){
        return(
            <div className="edit">
                {(this.state.saving || this.state.loadPermissions) && <Dialog><Loading /></Dialog>}
                <div className="header">
                        <h4>Edit Role</h4>
                    </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <RoleForm {...this.state} onChange={FormHelper(this, "role").onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <h4>Permissions</h4>
                        <div className="row-permissions">
                            {
                                this.state.permissions.map((permission, key) => <Checkbox key={key} name={permission.id} text={permission.name} checked={this.service.containsRolePermission(permission.id)} onChange={this.onChangePermission} />)
                            }
                        </div>
                    </RoleForm>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(EditRole))