import React from 'react'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="planId">
                    <div className="form-label">
                        <label>Plan</label>
                    </div>
                    <div className="form-input">
                        <select name="planId" onChange={props.onChange} value={props.planId}>
                            <option value=""></option>
                            {
                                props.locationPlans.map((locationPlan, key) => <option key={key} value={locationPlan.plan.id}>{locationPlan.plan.name} - N{locationPlan.plan.amount}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.planId}</span>
                    </div>
                </div>
                <div className="row" id="quantity">
                    <div className="form-label">
                        <label>Cycle</label>
                    </div>
                    <div className="form-input">
                        <select name="quantity" onChange={props.onChange} defaultValue={props.quantity}>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <span className="error">{props.errors.quantity}</span>
                    </div>
                </div>
                <div className="row" id="method">
                    <div className="form-label">
                        <label>Method</label>
                    </div>
                    <div className="form-input">
                        <select name="method" onChange={props.onChange} defaultValue={props.method}>
                            <option value=""></option>
                            <option value="2">Bank Transfer</option>
                            <option value="3">Cash</option>
                        </select>
                        <span className="error">{props.errors.method}</span>
                    </div>
                </div>
                <div className="row" id="salePointId">
                    <div className="form-label">
                        <label>SalePoint</label>
                    </div>
                    <div className="form-input">
                        <select name="salePointId" onChange={props.onChange} defaultValue={props.salePointId}>
                            <option value=""></option>
                            {
                                props.salePoints.map((salePoint, key) => <option key={key} value={salePoint.id}>{salePoint.location.name} - {salePoint.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.salePointId}</span>
                    </div>
                </div>
                <div className="row" id="reference">
                    <div className="form-label">
                        <label>Reference</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="reference" value={props.reference} onChange={props.onChange} />
                        <span className="error">{props.errors.reference}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default Form