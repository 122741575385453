import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import './customers.styles.scss'
import Service from './customers.service'
import Filters from './filters.component'
import AuthHelper from '../../../utils/AuthHelper'
import {GetStatusLabel} from './data/labels'

class Customers extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadCustomers()
        this.service.loadLocations()
    }

    componentDidUpdate() {
        let locationId = this.service.getLocationId()
        if (this.state.currentLocationId !== locationId) {
            this.setState({
                ...this.state,
                currentLocationId: locationId
            }, this.service.loadCustomers)
        }
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadCustomers())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadCustomers()
    }

    isActiveLocation = locationId => {
        return this.state.currentLocationId === locationId
    }

    render() {
        return (
            <>
                {(this.state.loadingCustomers || this.state.loadingLocations) && <Dialog><Loading /></Dialog>}
                <div className="customers">
                    <div className="header">
                        <div className="buttons locations">
                            {
                                this.state.locations.filter(location => AuthHelper(this.props.auth).hasPermissionInLocation([20,21,22,27], location.id)).map((location, key) => <Link className={this.isActiveLocation(location.id) ? 'btn active' : 'btn'} key={key} to={"/prepaid/customer/all?location=" + location.id}>{location.name}</Link>)
                            }
                        </div>
                    </div>
                    {
                        typeof this.state.currentLocationId !== 'undefined' && <>
                            <div className="header">
                                <h4>Customers</h4>
                                <div className="buttons">
                                    {
                                        AuthHelper(this.props.auth).canCreateCustomer() &&
                                        <Link className="btn" to={"/prepaid/customer/create?location=" + this.state.currentLocationId}><img alt="" src={iconNew} />New Customer</Link>
                                    }
                                    <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>UserID</th>
                                        <th>Name</th>
                                        <th>Current Plan</th>
                                        <th>Expiration</th>
                                        <th>Phone</th>
                                        <th>Type</th>
                                        <th>Site</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.customers.map((customer, key) => <tr key={key} onClick={() => this.props.history.push('/prepaid/customer/' + customer.id)}>
                                            <td className={GetStatusLabel(customer)}>{customer.userId}</td>
                                            <td>{customer.name}</td>
                                            <td>{customer.plan !== null ? customer.plan.name : "None"}</td>
                                            <td>{customer.expiration !== null ? new Date(customer.expiration * 1000).toString("dd MMM yyyy HH:mm:ss") : ""}</td>
                                            <td>{customer.phone}</td>
                                            <td>{customer.type === 1? 'Residential' : 'Enterprise'}</td>
                                            <td>{customer.site.name}, {customer.site.location.code}</td>
                                            <td>{new Date(customer.created * 1000).toString("dd MMM yyyy")}</td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                            {
                                this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                            }
                        </>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Customers))