import React from 'react'

const RoleForm = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.role.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="locationId">
                    <div className="form-label">
                        <label>Locations</label>
                    </div>
                    <div className="form-input">
                        <select name="locationId" onChange={props.onChange} value={props.role.locationId}>
                            <option value="">All</option>
                            {
                                props.locations.map((location, key) => <option key={key} value={location.id}>{location.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.locationId}</span>
                    </div>
                </div>
                <div className="row" id="salePointId">
                    <div className="form-label">
                        <label>SalePoints</label>
                    </div>
                    <div className="form-input">
                        <select name="salePointId" onChange={props.onChange} value={props.role.salePointId}>
                            <option value="">All</option>
                            {
                                props.salePoints.map((salePoint, key) => <option key={key} value={salePoint.id}>{salePoint.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.salePointId}</span>
                    </div>
                </div>
                {props.children}
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default RoleForm