import React from 'react'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="reason">
                    <div className="form-label">
                        <label>Reason</label>
                    </div>
                    <div className="form-input">
                        <select name="reason" onChange={props.onChange} value={props.reason}>
                            <option value=""></option>
                            <option value="0">Pause Subscription</option>
                            <option value="1">AUP Violation</option>
                        </select>
                        <span className="error">{props.errors.reason}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Lock</button>
            </div>
        </form>
    )
}

export default Form