import axios from 'axios'
import {API_ENDPOINT} from '../../config'

const all = (instance, params) => {
    instance.setState({ ...instance.state, loadingCustomers: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customers",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadCustomersResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingCustomers: false
            })
            alert(error)
        })
}

const handleLoadCustomersResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingCustomers: false,
                customers: response.data.data.customers,
                total: response.data.data.size
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingCustomers: false
            })
            alert(response.data.message)
    }
}

const one = (instance, params) => {
    instance.setState({ ...instance.state, loadingCustomer: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadCustomerResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingCustomer: false
            })
            alert(error)
        })
}

const handleLoadCustomerResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingCustomer: false,
                customer: response.data.data.customer
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingCustomer: false
            })
            alert(response.data.message)
    }
}

const CustomerDatasource = () => {
    return {
        all: (instance, params) => all(instance, params),
        one: (instance, params) => one(instance, params)
    }
}

export default CustomerDatasource