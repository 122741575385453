import Validation from '../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.plan.type, (error) => instance.state.errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.plan.name, (error) => instance.state.errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.plan.description, (error) => instance.state.errors.description = error, { min: { value: 2, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.plan.amount, (error) => instance.state.errors.amount = error, { min: { value: 1, error: "Invalid" }, max: { value: 1000000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.plan.profileId, (error) => instance.state.errors.profileId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.plan.expiredProfileId, (error) => instance.state.errors.expiredProfileId = error, { min: { value: 0, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.plan.validity, (error) => instance.state.errors.validity = error, { min: { value: 1, error: "Invalid" }, max: { value: 1000000, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

export const onChangeLocation = (instance, locationId) => {
    let locationPlans = instance.state.plan.locationPlans
    if (containsLocation(instance, locationId)) {
        locationPlans = instance.state.plan.locationPlans.filter(locationPlan => locationPlan.location.id !== locationId)
    }
    else {
        locationPlans.push({
            location: {
                id: locationId
            }
        })
    }
    instance.setState({
        ...instance.state,
        plan: {
            ...instance.state.plan,
            locationPlans: locationPlans
        }
    })
}

export const containsLocation = (instance, locationId) => {
    for (let i = 0; i < instance.state.plan.locationPlans.length; i++) {
        if (instance.state.plan.locationPlans[i].location.id === locationId) {
            return true
        }
    }
    return false
}

export const onChangeSalePoint = (instance, salePointId) => {
    let salePointPlans = instance.state.plan.salePointPlans
    if (containsLocation(instance, salePointId)) {
        salePointPlans = instance.state.plan.salePointPlans.filter(salePointPlan => salePointPlan.salePoint.id !== salePointId)
    }
    else {
        salePointPlans.push({
            salePoint: {
                id: salePointId
            }
        })
    }
    instance.setState({
        ...instance.state,
        plan: {
            ...instance.state.plan,
            salePointPlans: salePointPlans
        }
    })
}

export const containsSalePoint = (instance, salePointId) => {
    for (let i = 0; i < instance.state.plan.salePointPlans.length; i++) {
        if (instance.state.plan.salePointPlans[i].salePoint.id === salePointId) {
            return true
        }
    }
    return false
}