import axios from 'axios'
import {API_ENDPOINT} from '../../config'

const all = (instance, params) => {
    instance.setState({ ...instance.state, loadingLocationPlans: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/location/plans",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLocationPlansResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingLocationPlans: false
            })
            alert(error)
        })
}

const handleLocationPlansResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                locationPlans: response.data.data.locationPlans,
                loadingLocationPlans: false
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingLocationPlans: false
            })
            alert(response.data.message)
    }
}

const LocationPlanDatasource = () => {
    return {
        all: (instance, params) => all(instance, params)
    }
}

export default LocationPlanDatasource