import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="type">
                    <div className="form-label">
                        <label>Type</label>
                    </div>
                    <div className="form-input">
                        <select name="type" onChange={props.onChange} value={props.customer.type}>
                            <option value=""></option>
                            <option value="1">Residential</option>
                            <option value="0">Enterprise</option>
                        </select>
                        <span className="error">{props.errors.type}</span>
                    </div>
                </div>
                {
                    String(props.customer.type) === "0" && <>
                        <div className="row" id="userId">
                            <div className="form-label">
                                <label>UserID</label>
                            </div>
                            <div className="form-input">
                                <input type="text" name="userId" value={props.customer.userId} onChange={props.onChange} />
                                <span className="error">{props.errors.userId}</span>
                            </div>
                        </div>
                        <div className="row" id="ipAddress">
                            <div className="form-label">
                                <label>IP Address</label>
                            </div>
                            <div className="form-input">
                                <input type="text" name="ipAddress" value={props.customer.ipAddress} onChange={props.onChange} />
                                <span className="error">{props.errors.ipAddress}</span>
                            </div>
                        </div>
                    </>
                }
                <div className="row" id="name">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.customer.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="phone">
                    <div className="form-label">
                        <label>Phone</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="phone" value={props.customer.phone} onChange={props.onChange} />
                        <span className="error">{props.errors.phone}</span>
                    </div>
                </div>
                <div className="row" id="email">
                    <div className="form-label">
                        <label>Email</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="email" value={props.customer.email} onChange={props.onChange} />
                        <span className="error">{props.errors.email}</span>
                    </div>
                </div>
                <div className="row" id="address">
                    <div className="form-label">
                        <label>Address</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="address" value={props.customer.address} onChange={props.onChange} />
                        <span className="error">{props.errors.address}</span>
                    </div>
                </div>
                <div className="row" id="siteId">
                    <div className="form-label">
                        <label>Site</label>
                    </div>
                    <div className="form-input">
                        <select name="siteId" onChange={props.onChange} value={props.customer.siteId}>
                            <option value=""></option>
                            {
                                props.sites.map((site, key) => <option key={key} value={site.id}>{site.name} - {site.location.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.siteId}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/prepaid/customer/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)