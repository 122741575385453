export const GetStatusLabel = customer => {
    if(customer.status === 1){
        return 'locked'
    }

    if(customer.expired){
        return 'expired'
    }

    if(customer.online){
        return 'online'
    }

    return ''
}