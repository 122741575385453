import CustomerDatasource from '../../../../data/datasource/customer.datasource'

const getDefaultState = () => {
    return {
        loadingCustomer: false,
        showAddPlan: false,
        showLock: false,
        showUnlock: false,
        showChangePlan: false,
        showMakeActive: false,
        showEdit: false,
        showDisconnect: false,
        customer: {
            id: "",
            name: "",
            phone: "",
            company: "",
            address: "",
            site: {
                name: "",
                location: {
                    name: ""
                },
            },
            expired: true,
            activeCustomerPlans: [],
            plan: null,
            expiration: null
        },
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadCustomer = instance => {

    CustomerDatasource().one(instance, {
        id: instance.props.match.params.id
    })
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadCustomer: () => loadCustomer(instance)
    }
}

export default Service