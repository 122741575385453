import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="days">
                    <div className="form-label">
                        <label>Add/Substract Days</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="days" value={props.days} placeholder="eg 12 or -7" onChange={props.onChange} />
                        <span className="error">{props.errors.days}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)