import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './unlock.styles.scss'
import Service from './unlock.service'
import Loading from '../../../../../../components/loading/loading.component'

class Unlock extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.unlock()
    }

    render() {
        return (
            <div className="unlock card">
                <div className="header">
                    <h4>Unlocking {this.props.customer.userId}</h4>
                </div>
                <Loading/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Unlock))