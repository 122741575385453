import axios from 'axios'
import {API_ENDPOINT} from '../../config'

export const LoadRoles = instance => {
    instance.setState({ ...instance.state, loadingRoles: true })
    let criteria = {}
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/roles",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadRolesResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingRoles: false
            })
            alert(error)
        })
}

const all = (instance, params) => {
    instance.setState({ ...instance.state, loadingRoles: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/roles",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadRolesResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingRoles: false
            })
            alert(error)
        })
}

const handleLoadRolesResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingRoles: false,
                roles: response.data.data.roles
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingRoles: false
            })
            alert(response.data.message)
    }
}

const one = (instance, params) => {
    instance.setState({ ...instance.state, loadingRole: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/role",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadRoleResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingRole: false
            })
            alert(error)
        })
}

const handleLoadRoleResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingRole: false,
                role: {
                    ...response.data.data.role,
                    salePointId: response.data.data.role.salePoint !== null? response.data.data.role.salePoint.id: "",
                    locationId: response.data.data.role.location !== null? response.data.data.role.location.id: ""
                }
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingRole: false
            })
            alert(response.data.message)
    }
}

const RoleDatasource = () => {
    return {
        all: (instance, params) => all(instance, params),
        one: (instance, params) => one(instance, params)
    }
}

export default RoleDatasource