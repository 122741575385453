import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './create.service'
import './create.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import Form from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'

class Create extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadProfiles()
        this.service.loadLocations()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    onChangeLocation = (locationId, checked) => this.service.onChangeLocation(locationId)

    render() {
        return (
            <div className="create">
                {(this.state.saving || this.state.loadLocations|| this.state.loadingProfiles) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Create Plan</h4>
                </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form {...this.state} onChange={FormHelper(this, "plan").onChange} onSubmit={this.onSubmit} onLocationChanged={this.onLocationChanged}>
                        <h4>Allowed Locations</h4>
                        <div className="row-roles">
                            {
                                this.state.locations.map((location, key) => <Checkbox key={key} text={location.name} name={location.id} checked={this.service.containsLocation(location.id)} onChange={this.onChangeLocation} />)
                            }
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Create))