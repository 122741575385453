import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import { isValid } from '../common.service'
import SiteDatasource from '../../../../data/datasource/site.datasource'
import queryString from 'query-string'

const getDefaultState = () => {
    return {
        loadingLocations: false,
        saving: false,
        customer: {
            type: "",
            userId: "",
            ipAddress: "",
            name: "",
            phone: "",
            email: "",
            company: "",
            address: "",
            siteId: ""
        },
        sites: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadSites = instance => {
    SiteDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {
            location: getLocationId(instance)
        }
    })
}

const getLocationId = instance => {
    let query = queryString.parse(instance.props.location.search);
    return query.location
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.customer
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadSites: () => loadSites(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service