import SiteDatasource from '../../../../../../data/datasource/site.datasource'
import { isValid } from '../../../common.service'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = instance => {
    return {
        saving: false,
        loadingSites: false,
        customer:{
            ...instance.props.customer,
            siteId: instance.props.customer.site.id
        },
        showPassword: false,
        sites: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadSites = instance => {

    SiteDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {
            location: instance.props.customer.site.location.id
        }
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.customer
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            instance.props.onCustomerReload(response.data.data.customer)
            instance.props.onClose()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadSites: () => loadSites(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service