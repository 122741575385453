import LocationPlanDatasource from '../../../../../../data/datasource/location-plan.datasource'
import Validation from '../../../../../../data/validation/validation'
import { StringValidationRule } from '../../../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../../../utils/UIHelper'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = instance => {
    return {
        saving: false,
        loadingLocationPlans: true,
        planId: instance.props.customer.plan !== null? instance.props.customer.plan.id: "",
        locationPlans: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadLocationPlans = instance => {

    LocationPlanDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {
            location: instance.props.customer.site.location.id
        }
    })
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.planId, (error) => instance.state.errors.planId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/customer/change-plan",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.customer.id,
            planId: instance.state.planId
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            instance.props.onPlanChanged(findPlanById(instance, instance.state.planId))
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const findPlanById = (instance, planId) => {
    for(let i = 0; i < instance.state.locationPlans.length; i++){
        if(planId === instance.state.locationPlans[i].plan.id){
            return instance.state.locationPlans[i].plan
        }
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadLocationPlans: () => loadLocationPlans(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service