import React from 'react'
import { Switch, Route } from "react-router-dom"
import Customers from './customers.page'
import Create from './create/create.page'
import Details from './details/details.page'
import './common.styles.scss'

const CustomerController = () => {
    return (
        <div className="customer">
            <Switch>
                <Route path="/prepaid/customer/create" component={Create} />
                <Route path="/prepaid/customer/all" component={Customers} />
                <Route path="/prepaid/customer/:id" component={Details} />
            </Switch>
        </div>
    )
}

export default CustomerController