import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './details.service'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import Info from './components/info/info.component'
import AddPlan from './components/add-plan/add-plan.component'
import Edit from './components/edit/edit.component'
import Disconnect from './components/disconnect/disconnect.component'
import ChangePlan from './components/change-plan/change-plan.component'
import EditLastPlan from './components/edit-last-plan/edit-last-plan.component'
import Lock from './components/lock/lock.component'
import Unlock from './components/unlock/unlock.component'

class Detail extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadCustomer()
    }

    toggleShowAddPlan = () => this.setState({
        ...this.state,
        showAddPlan: !this.state.showAddPlan
    })

    toggleShowLock = () => this.setState({
        ...this.state,
        showLock: !this.state.showLock
    })

    toggleShowUnlock = () => this.setState({
        ...this.state,
        showUnlock: !this.state.showUnlock
    })

    toggleShowChangePlan = () => this.setState({
        ...this.state,
        showChangePlan: !this.state.showChangePlan
    })

    toggleShowMakeActive = () => this.setState({
        ...this.state,
        showMakeActive: !this.state.showMakeActive
    })

    toggleShowEdit = () => {
        this.setState({
            ...this.state,
            showEdit: !this.state.showEdit
        })
    }

    toggleShowDisconnect = () => {
        this.setState({
            ...this.state,
            showDisconnect: !this.state.showDisconnect
        })
    }

    onCustomerReload = customer => {
        this.setState({
            ...this.state,
            customer: customer
        })
    }

    reloadPage = () => window.location.reload();

    render() {
        return (
            <div className="details">
                {this.state.loadingCustomer && <Dialog><Loading /></Dialog>}
                {this.state.showAddPlan && <Dialog><AddPlan customer={this.state.customer} onClose={this.toggleShowAddPlan} onCustomerPlanAdded={this.reloadPage}/></Dialog>}
                {this.state.showLock && <Dialog><Lock customer={this.state.customer} onClose={this.toggleShowLock} onCustomerUpdated={this.reloadPage}/></Dialog>}
                {this.state.showUnlock && <Dialog><Unlock customer={this.state.customer} onClose={this.toggleShowUnlock} onCustomerUpdated={this.reloadPage}/></Dialog>}
                {this.state.showChangePlan && <Dialog><ChangePlan customer={this.state.customer} onPlanChanged={this.reloadPage}  onClose={this.toggleShowChangePlan}/></Dialog>}
                {this.state.showMakeActive && <Dialog><EditLastPlan id={this.state.customer.id} onPlanChanged={this.reloadPage} onClose={this.toggleShowMakeActive}/></Dialog>}
                {this.state.showEdit && <Dialog><Edit customer={this.state.customer} onClose={this.toggleShowEdit} onCustomerReload={this.reloadPage}/></Dialog>}
                {this.state.showDisconnect && <Dialog><Disconnect customer={this.state.customer} onClose={this.toggleShowDisconnect}/></Dialog>}
                <div className="header">
                    <h4>Customer Details</h4>
                </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Info key={this.state.customer.id} {...this.state.customer} onUnlockClick={this.toggleShowUnlock} onLockClick={this.toggleShowLock} onAddPlanClick={this.toggleShowAddPlan} onChangePlanClick={this.toggleShowChangePlan} onMakeActiveClick={this.toggleShowMakeActive} onEditClick={this.toggleShowEdit} onDisconnectClick={this.toggleShowDisconnect}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Detail))