import axios from 'axios'
import { LoadLocations } from '../../../../data/datasource/location.datasource'
import ProfileDatasource from '../../../../data/datasource/profile.datasource'
import { API_ENDPOINT } from '../../../../config'
import { IsValid, onChangeLocation, containsLocation } from '../common.service'

const getDefaultState = instance => {
    return {
        saving: false,
        loadingLocations: false,
        loadingProfiles: false,
        plan: {
            name: "",
            description: "",
            profileId: "",
            expiredProfileId: "",
            type: "",
            amount: "",
            validity: "",
            locationPlans: []
        },
        locations: [],
        profiles: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadProfiles = instance => {
    ProfileDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {}
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/plan/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.plan
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                plan: {
                    ...getDefaultState(instance).plan
                },
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => IsValid(instance),
        loadLocations: () => LoadLocations(instance),
        loadProfiles: () => loadProfiles(instance),
        onChangeLocation: locationId => onChangeLocation(instance, locationId),
        containsLocation: locationId => containsLocation(instance, locationId),
        save: () => save(instance)
    }
}

export default Service