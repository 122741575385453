import PermissionDatasource from '../../../data/datasource/permission.datasource'
import {LoadLocations} from '../../../data/datasource/location.datasource'
import SalePointDatasource from '../../../data/datasource/salepoint.datasource'
import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.role.name, (error) => errors.name = error, { min: { value: 2, error: "Too short" }, max: { value: 20, error: "Too long" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

export const loadPermissions = instance => {
    PermissionDatasource().all(instance, {
        offset: 0,
        limit: 100,
        criteria: {}
    })
}

export const loadLocations = instance => {
    LoadLocations(instance, {
        offset: 0,
        limit: 100,
        criteria: {}
    })
}

export const loadSalePoints = instance => {
    SalePointDatasource().all(instance, {
        offset: 0,
        limit: 100,
        criteria: {}
    })
}

export const onChangeRolePermission = (instance, permissionId) => {
    let rolePermissions = instance.state.role.rolePermissions
    if (containsRolePermission(instance, permissionId)) {
        rolePermissions = instance.state.role.rolePermissions.filter(rolePermission => rolePermission.permission.id !== permissionId)
    }
    else {
        rolePermissions.push({
            permission: {
                id: permissionId
            }
        })
    }
    instance.setState({
        ...instance.state,
        role: {
            ...instance.state.role,
            rolePermissions: rolePermissions
        }
    })
}

export const containsRolePermission = (instance, permissionId) =>  {
    for(let i = 0; i < instance.state.role.rolePermissions.length; i++){
        if(instance.state.role.rolePermissions[i].permission.id === permissionId){
            return true
        }
    }
    return false
}
