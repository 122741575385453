import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="code">
                    <div className="form-label">
                        <label>Code</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="code" value={props.code} onChange={props.onChange} />
                        <span className="error">{props.errors.code}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="customerSequenceStart">
                    <div className="form-label">
                        <label>Customer Sequence Start</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="customerSequenceStart" value={props.customerSequenceStart} onChange={props.onChange} />
                        <span className="error">{props.errors.customerSequenceStart}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/internet/locations/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)